/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "~ngx-toastr/toastr";
@import "./variables";

// @import "~bootstrap/scss/bootstrap";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
// @import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
// @import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
// @import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/list-group";
// @import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
// @import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
// @import "placeholders";
// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// Swiper CSS
@import "~swiper/swiper-bundle";
// scss-docs-end import-stack

@import "./input";
// @import "./ng-select";
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@font-face {
  font-family: "ABC Whyte Inktrap";
  src: url("/assets/fonts/Whyte_Inktrap/ABCWhyteInktrap-Bold.woff2")
      format("woff2"),
    url("/assets/fonts/Whyte_Inktrap/ABCWhyteInktrap-Bold.woff") format("woff");
}
@import "./table";

// BASIC
html {
  overscroll-behavior: none;
  overflow-y: hidden;
  font-size: 18px !important; // Determines rem size
}
@media (max-width: 768px) {
  html {
    font-size: 17px !important;
  }
}
@media (max-width: 520px) {
  html {
    font-size: 16px !important;
  }
}

body {
  background-color: $white;
  color: $green-dark !important;
  font-family: $Roboto !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  // @include elevator-scrollbarV1;
  overflow-y: auto !important;
  overflow-x: hidden;
  @include main-scrollbar;
}

.green {
  color: $green !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $Anton;
  font-size: $font-xxxl !important;
  font-weight: normal !important;
  line-height: 1.1 !important;
  letter-spacing: 0em !important;
  text-align: center;
  text-transform: uppercase;
}

h2 {
  font-size: $font-xxl !important;
}

h3 {
  font-size: $font-xl !important;
}

h4 {
  font-size: $font-lg !important;
}

h5 {
  font-size: $font-md !important;
}

h6 {
  font-size: $font-sm !important;
}

.text-small,
.label-small,
.label-big {
  font-family: $Roboto;
  font-size: $font-sm !important;
  font-weight: 400 !important;
  line-height: 1.1 !important;
  letter-spacing: 0em !important;
}
.label-small {
  font-size: $font-xs !important;
  font-weight: 500 !important;
  text-align: center;
}
.label-big {
  font-size: $font-md !important;
  font-weight: 500 !important;
  text-align: center;
}

// @media (max-width: 520px) {
//   h1 {
//     font-size: 36px !important;
//     line-height: 38px !important;
//   }
//   h2 {
//     font-size: 28px !important;
//     line-height: 30px !important;
//   }
//   h3 {
//     font-size: 22px !important;
//     line-height: 24px !important;
//   }
//   h4 {
//     font-size: 20px !important;
//     line-height: 22px !important;
//   }
//   h5 {
//     font-size: 18px !important;
//     line-height: 20px !important;
//   }
//   h6 {
//     font-size: 16px !important;
//     line-height: 18px !important;
//   }
// }
// @media (max-width: 360px) {
//   h1 {
//     font-size: 28px !important;
//     line-height: 30px !important;
//   }
//   h2 {
//     font-size: 24px !important;
//     line-height: 26px !important;
//   }
//   h3 {
//     font-size: 20px !important;
//     line-height: 22px !important;
//   }
//   h4 {
//     font-size: 18px !important;
//     line-height: 20px !important;
//   }
//   h5 {
//     font-size: 16px !important;
//     line-height: 18px !important;
//   }
// }

// CARD
.card {
  border-radius: 36px;
  border: 1px solid $green;
  box-shadow: 8px 8px 0px 0px $green;
  padding: 20px;
}
.card-light {
  border-radius: 36px;
  border: 1px solid $green-light;
  box-shadow: 8px 8px 0px 0px $green-light;
  padding: 20px;
}
@media (max-width: 768px) {
  .card {
    border-radius: 16px;
    padding: 8px;
  }
  .card-light {
    border-radius: 16px;
    padding: 8px;
  }
}

@media (max-width: 768px) {
  table {
    overflow-x: auto;
    td {
      min-width: 36px;
    }
  }
  h1 {
    font-size: $font-xl !important;
  }
  h2 {
    font-size: $font-lg !important;
  }
  h3 {
    font-size: $font-md !important;
  }
  h4 {
    font-size: $font-sm !important;
  }
  h5 {
    font-size: $font-sm !important;
  }
  h6 {
    font-size: $font-xs !important;
  }
}

/* #region BUTTONS */
.btn,
.btn-mobile {
  font-family: $Anton;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 24px;
  height: fit-content;
  min-height: 48px;
}

.btn-mobile {
  border-radius: 40px;
  min-height: 52px;
}

.btn-icon {
  padding: 0.25rem 0.25rem !important;
}

.btn-blob {
  color: $white;
  background-color: $primary-80;
  border: 1px solid $white;
  box-shadow: 4px 4px 0px 0px $green-light;
  transition: all 100ms linear;
  &:hover {
    color: $white;
    background-color: $primary-100;
  }
  &:disabled {
    background-color: $green-light;
    opacity: 1;
  }
}

.btn-social {
  color: $green;
  background-color: $white;
  border: 1px solid $green;
  &:hover {
    color: $white;
    background-color: $green;
  }
}

.btn-green {
  color: $neutral-0;
  background-color: $primary-80;
  border: none;
  &:hover {
    color: $neutral-0;
    background-color: $primary-100;
  }
  &:disabled {
    background-color: $neutral-20;
    color: $neutral-0;
  }
}

.btn-green-outline {
  color: $primary-80;
  background-color: $white;
  border: 1.5px solid $primary-80;
  &:hover {
    color: $primary-100;
    background-color: $white;
    border-color: $primary-100;
  }
}

.btn-green-reverse {
  color: $primary-80;
  background-color: $white;
  &:hover {
    color: $primary-100;
    background-color: $white;
  }
}

.btn-green-reverse-outline {
  color: $white;
  background-color: $primary-80;
  border: 1px solid $white;
  &:hover {
    color: $white;
    background-color: $primary-100;
  }
}

.btn-green-light-dark {
  color: $primary-80;
  background-color: $green-light;
  &:hover {
    color: $white;
    background-color: $primary-100;
  }
}

.btn-blue {
  color: $blue;
  background-color: $white;
  outline: 1px solid $blue;
  &:hover {
    color: $white;
    background-color: $blue;
  }
}
.btn-yellow {
  color: $yellow;
  background-color: $white;
  outline: 1px solid $yellow;
  &:hover {
    color: $white;
    background-color: $yellow;
  }
}
/* #endregion BUTTONS */

// HOVERABLE IMG
.hover-img-radius {
  border-radius: 70px;
  transition: border-radius 300ms linear;
  &:hover {
    border-radius: 30px;
  }
}

// RESPONSIVE 2 COLUMN GRID
.form-responsive {
  --grid-column-count: 2;
  &.single-col {
    --grid-column-count: 1;
  }
  &.three-cols {
    --grid-column-count: 3;
  }
  --grid-layout-gap: 20px;
  --grid-item-min-width: 300px;
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item-max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item-min-width), var(--grid-item-max-width)), 1fr)
  );
  column-gap: var(--grid-layout-gap);
  .form-group {
    min-width: var(--grid-item-min-width);
  }
  --grid-responsive-padding: 1rem;
  @media (max-width: 550px) {
    padding-left: var(--grid-responsive-padding) !important;
    padding-right: var(--grid-responsive-padding) !important;
    grid-template-columns: repeat(
      auto-fill,
      minmax(
        max(
          calc(100% - calc(var(--grid-responsive-padding) * 2)),
          var(--grid-item-max-width)
        ),
        1fr
      )
    );
    .form-group {
      width: calc(100% - calc(var(--grid-responsive-padding) * 2));
      min-width: 100%;
    }
  }
}

.elevator-scrollbar {
  overflow-y: auto;
  @include elevator-scrollbarV2;
}

tui-root > tui-scroll-controls {
  display: none;
}

.font-size-16 {
  font-size: $font-xs;
}

.bg-grey {
  background-color: var(--tui-top-title-bg);
}

.tui-tab {
  &._active {
    font-weight: bold;
    color: var(--tui-primary) !important;
  }
  font-size: $font-xxs;
  color: var(--tui-secondary) !important;
}

button[data-appearance="primary"] {
  color: $white;
  background-color: $green;
  &:hover {
    color: $white;
    background-color: $green-dark;
  }
  &:disabled,
  &._disabled,
  &.disabled {
    color: $white; // not working
  }
}
button[data-appearance="secondary"] {
  color: $green;
  background-color: $white;
  border: 1px solid $green;
  &:hover {
    color: $green-dark;
    background-color: $white;
    border-color: $green-dark;
  }
  &:disabled,
  &._disabled,
  &.disabled {
    color: $green; // not working
  }
}

.btn-primary,
.btn-primary:hover {
  color: #fff !important;
}

.form-group {
  margin-bottom: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu-lg {
  min-width: 20rem;
}

.font-weight-medium {
  font-weight: 500;
}

.button-detail-toggle {
  font-weight: bold;
  color: var(--tui-primary);
  text-decoration: none;
}
.button-detail-toggle:hover {
  color: var(--tui-primary-hover);
}

tui-checkbox-labeled {
  tui-wrapper {
    --animation-duration: 400ms;
    @include twist-animation(var(--animation-duration), 0ms);
    &[data-appearance="outline"] {
      // unchecked
      background-color: $green-light !important;
    }
    &[data-appearance="primary"] {
      // checked
      background-color: $green-dark !important;
      color: $green-dark !important;
      border-radius: inherit !important;
      @include twist-reverse-animation(var(--animation-duration), 0ms);
    }
  }
}
tui-radio {
  tui-wrapper {
    &[data-appearance="outline"] {
      // unchecked
      background-color: $green-light !important;
    }
    &[data-appearance="primary"] {
      // checked
      background-color: $green-dark !important;
      color: $green-dark !important;
    }
  }
}

tui-wrapper[data-appearance="icon-header"] {
  background: none;
  color: var(--tui-primary);
}
tui-wrapper[data-appearance="primary"] {
  border-radius: 10px;
  font-weight: 600;

  &[data-state="disabled"] {
    background: #fff !important;
    border: 1px solid var(--tui-primary) !important;
    color: var(--tui-primary) !important;
  }
}
tui-wrapper[data-appearance="secondary"] {
  background: #fff !important;
  border: 1px solid var(--tui-primary) !important;
  color: var(--tui-primary) !important;
  border-radius: 10px;
  font-weight: 600;

  &[data-state="disabled"] {
    background: #fff !important;
    border: 1px solid var(--tui-primary) !important;
    color: var(--tui-primary) !important;
  }
}
tui-wrapper[data-appearance="flat"] {
  font-weight: 600;
  font-size: $font-xs;
  padding: 0 !important;
  color: var(--tui-secondary) !important;
  &[data-state="hovered"] {
    background: transparent !important;
    border: 0px solid var(--tui-primary) !important;
    color: var(--tui-primary) !important;
  }
}
.primary-color {
  color: var(--tui-primary);
}

.hiddenRow {
  padding: 0 !important;
}

.hide-bottom-border {
  border-bottom: hidden !important;
}

.menu-actions {
  border-left: 1px solid #dee2e6;
  .nav-link {
    color: #00ab8e !important;
    padding: 0;
    i {
      font-size: $font-sm;
    }
  }
  .nav-pills {
    .nav-link.active {
      color: #fff !important;
      background-color: var(--tui-primary);
    }
    li.nav-item:last-child .nav-link {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
    li.nav-item:first-child .nav-link {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }
}

.menu-panel {
  box-shadow: 0px 6px 6px #71757f29;

  .nav-item .nav-link {
    color: var(--bs-body-color);
    font-weight: bold;
  }
  .nav-item.active .nav-link {
    color: var(--tui-primary);
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 72%;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid var(--tui-primary);
      transform: rotate(180deg);
      clear: both;
    }
  }
}

.private-header-menu {
  box-shadow: 0px 6px 6px #71757f29;

  .navbar {
    height: 57px;
    .navbar-brand {
      margin-right: 2rem;
    }
  }
  // large header to fit icons
  @media screen and (max-width: 800px) {
    .navbar {
      height: 114px;
      .navbar-brand {
        margin-right: 2rem;
        margin-left: 1rem;
        margin-top: 0.4rem;
      }
    }
  }

  .nav-item .nav-link {
    color: var(--bs-body-color);
    font-weight: bold;
  }

  .nav-item.active .nav-link {
    color: var(--tui-primary);
  }

  // hide arrow under menu items
  .offcanvas-start:not(.show) .nav-item.active .nav-link {
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 100%;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid var(--tui-primary);
      transform: rotate(180deg);
      clear: both;
    }
  }
}

.border-bottom-grey {
  border-bottom: 1px solid #cbcbcb;
}

.border-right-grey {
  border-right: 1px solid #cbcbcb;
}

.border-top-grey {
  border-top: 1px solid #cbcbcb;
}

.mark {
  background-color: transparent;
}

.bg-dark-dropdown {
  background-color: #484c4d;
}

.tui-link-user-panel {
  color: $black !important;
  &:hover {
    color: $green-dark !important;
    font-weight: bold !important;
  }
}

.tui-link-box-counter {
  font-weight: bold;
  font-size: $font-sm;
}

.text-color-dark {
  //color: #5f615e !important;
  color: var(--tui-secondary) !important;
}

.table {
  tbody tr {
    vertical-align: middle;
  }
}
.marker {
  background: var(tui-input-grey) !important;
  border: 0px solid var(--tui-primary) !important;
  font-size: $font-md !important;
  color: var(--tui-primary) !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
  padding-bottom: 0.25rem !important;
}
._active .marker {
  background: var(--tui-primary) !important;
  border: 0px solid var(--tui-primary) !important;
  color: #fff !important;
}
tui-dialog-host {
  div.overlay {
    background-color: rgba(0, 0, 0, 0.4) !important;
  }
  section:has(> tui-dialog) {
    overflow: hidden !important;
  }
}
tui-dialog {
  border-radius: 30px !important;
  font-size: $font-sm !important;
  & > div.t-wrapper {
    button {
      position: absolute !important;
      left: calc(50% - 35px);
      color: $white !important;
      background-color: $green !important;
      border: 1px solid $white;
      box-shadow: 4px 4px 0px 0px $green-light;
      margin-top: -45px;
      padding: 10px 20px;
      opacity: 1;
      height: 47px !important;
      width: 66px !important;
      transition: all 100ms linear !important;
      &:hover {
        color: $white !important;
        background-color: $green-dark !important;
        padding: 15px;
        left: calc(50% - 30px);
        transform: translateY(-5px);
        height: 55px !important;
        width: 55px !important;
      }
      &:disabled {
        background-color: $green-light !important;
        opacity: 1;
      }
      tui-svg {
        font-size: $font-md;
      }
    }
  }
  & > header {
    box-shadow: 4px 4px 0px 0px $green-dark;
    & > * {
      padding-top: 2rem;
    }
  }
  & > div.t-content {
    border: 1px solid $green-dark;
    box-shadow: 4px 4px 0px 0px $green-dark;
  }
  div.t-content {
    padding: 0rem !important;
    padding-top: 20px !important;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
    // -ms-overflow-style: none;  /* IE and Edge */
    // scrollbar-width: none;  /* Firefox */
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    @include dialog-scrollbar;
    h2 {
      background: var(--tui-top-title-bg) !important;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 0.5rem;
    }
    section {
      padding: 0.5rem 2rem 1rem !important;
      div.form-group {
        margin-top: 0 !important;
      }
      tui-input-file {
        .wrapper:hover {
          background: transparent !important;
        }
      }
      app-radios-input {
        .wrapper {
          margin-top: 1.5rem !important;
        }
      }
      & > div {
        text-align: center !important;
      }
      app-form {
        label,
        input,
        label > * {
          font-size: $font-sm !important;
        }
      }
    }
  }
  .tui-text_h3 {
    font-size: $font-sm !important;
  }
  .tui-text_h5 {
    font-size: $font-sm !important;
  }
  button.close {
    position: absolute !important;
    top: 0.5rem !important;
    right: 0.5rem !important;
    color: var(--tui-primary) !important;
    background: #f8f8f8 !important;
    tui-svg {
      font-size: $font-md !important;
      font-weight: normal !important;
    }
  }
  tui-wrapper {
    div.t-content {
      padding: 2rem !important;
    }
  }
  tui-checkbox-block tui-wrapper {
    div.t-content {
      padding: 0 1rem !important;
    }
  }
}
tui-dropdown-box .content .dropdown {
  width: 460px;
  @media only screen and (max-width: 720px) {
    width: auto;
  }
}
tui-dropdown-box {
  border: 2px solid $green-light !important;
  box-shadow: 4px 4px 0 0 $green-light !important;
  .content {
    height: inherit !important;
    overflow-y: auto;
    overflow-x: hidden;
    @include dropdown-scrollbar;
  }
  tui-data-list {
    tui-select-option,
    tui-multi-select-option {
      color: #484c4d;
    }
  }
}

.ac-pushButton {
  border: 1px solid #00ab8e !important;
  font-weight: bold;
  border-radius: 20px;
  color: black !important;
  &[aria-pressed="true"] {
    background-color: #005151 !important;
    border: 1px solid #005151 !important;
    color: white !important;
  }
}
.webchat__initialsAvatar {
  background: url("/assets/icons/chatbot.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border: 1px solid #00ab8e !important;
  border-radius: 50% !important;
  &.webchat__initialsAvatar--fromUser {
    background: url("/assets/icons/user.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }
}

.webchat__suggested-actions__item-box {
  border-radius: 10px;
}
.ac-container.ac-adaptiveCard {
  border-radius: $style-radius;
  .ac-pushButton {
    div {
      white-space: break-spaces !important;
    }
  }
}
.webchat__bubble__content {
  border-radius: 20px !important;
  border: 1px solid #00ab8e !important;
}
.webchat__bubble--from-user {
  .webchat__bubble__content {
    border: 1px solid #005151 !important;
    background: #005151 !important;
  }
  p {
    background: #005151 !important;
    color: white !important;
    font-family: "Raleway";
    font-weight: 600;
  }
}
tui-dialog div.t-content section .webchat__basic-transcript__transcript {
  padding: 0px !important;
}

tui-wrapper[data-appearance="textfield"],
[tuiWrapper][data-appearance="textfield"] {
  background: var(--tui-input-grey) !important;
}

.text-primary {
  color: var(--tui-primary) !important;
}

*:has(> table) {
  overflow-x: auto;
  @include fancy-scrollbar;
}

@media (max-width: 450px) {
  .mx-5:not(.ngm),
  .mx-4:not(.ngm),
  .mx-3:not(.ngm),
  .m-5:not(.ngm),
  .m-4:not(.ngm),
  .m-3:not(.ngm) {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
}

tui-hint-box {
  background: $white-green !important;
  border-radius: var(--tui-radius-m);
  color: var(--tui-text-01);
  box-shadow: 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 100;
}

tui-dialog tui-multi-select tui-wrapper div.t-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.float-end {
  float: inline-end;
}

.row-gap-lg {
  row-gap: $m-lg;
}
.row-gap-md {
  row-gap: $m-md;
}
.row-gap-sm {
  row-gap: $m-sm;
}

// FIX Filtri sopra a menu
tui-dropdown-host {
  tui-dropdown-box {
    z-index: 100 !important;
  }
}

// SOLUZIONE NON IDEALE, SAREBBE DA MODIFICARE OGNI RIFERIMENTO A PADDING O MARGIN DI BOOTSTRAP USANDO I BREAKPOINT
@media (max-width: 640px) {
  .mb-1:not(.ngm),
  .mb-2:not(.ngm) {
    margin-bottom: $m-xs !important;
  }
  .mb-3:not(.ngm),
  .mb-4:not(.ngm),
  .mb-5:not(.ngm) {
    margin-bottom: $m-sm !important;
  }
  .mt-1:not(.ngm),
  .mt-2:not(.ngm) {
    margin-top: $m-xs !important;
  }
  .mt-3:not(.ngm),
  .mt-4:not(.ngm),
  .mt-5:not(.ngm) {
    margin-top: $m-sm !important;
  }
  .ml-1:not(.ngm),
  .ml-2:not(.ngm) {
    margin-left: $m-xs !important;
  }
  .ml-3:not(.ngm),
  .ml-4:not(.ngm),
  .ml-5:not(.ngm) {
    margin-left: $m-sm !important;
  }
  .mr-1:not(.ngm),
  .mr-2:not(.ngm) {
    margin-right: $m-xs !important;
  }
  .mr-3:not(.ngm),
  .mr-4:not(.ngm),
  .mr-5:not(.ngm) {
    margin-right: $m-sm !important;
  }
  .mx-1:not(.ngm),
  .mx-2:not(.ngm) {
    margin-left: $m-xs !important;
    margin-right: $m-xs !important;
  }
  .mx-3:not(.ngm),
  .mx-4:not(.ngm),
  .mx-5:not(.ngm) {
    margin-left: $m-sm !important;
    margin-right: $m-sm !important;
  }
  .my-1:not(.ngm),
  .my-2:not(.ngm) {
    margin-top: $m-xs !important;
    margin-bottom: $m-xs !important;
  }
  .my-3:not(.ngm),
  .my-4:not(.ngm),
  .my-5:not(.ngm) {
    margin-top: $m-sm !important;
    margin-bottom: $m-sm !important;
  }
  .m-1:not(.ngm),
  .m-2:not(.ngm) {
    margin-top: $m-xs !important;
    margin-bottom: $m-xs !important;
    margin-left: $m-xs !important;
    margin-right: $m-xs !important;
  }
  /*
  .m-3:not(.ngm),
  .m-4:not(.ngm),
  .m-5 :not(.ngm) {
    margin-top: $m-sm !important;
    margin-bottom: $m-sm !important;
    margin-left: $m-sm !important;
    margin-right: $m-sm !important;
  }
  */
  .pb-1:not(.ngm),
  .pb-2:not(.ngm) {
    padding-bottom: $m-xs !important;
  }
  .pb-3:not(.ngm),
  .pb-4:not(.ngm),
  .pb-5:not(.ngm) {
    padding-bottom: $m-sm !important;
  }
  .pt-1:not(.ngm),
  .pt-2:not(.ngm) {
    padding-top: $m-xs !important;
  }
  .pt-3:not(.ngm),
  .pt-4:not(.ngm),
  .pt-5:not(.ngm) {
    padding-top: $m-sm !important;
  }
  .pl-1:not(.ngm),
  .pl-2:not(.ngm) {
    padding-left: $m-xs !important;
  }
  .pl-3:not(.ngm),
  .pl-4:not(.ngm),
  .pl-5:not(.ngm) {
    padding-left: $m-sm !important;
  }
  .pr-1:not(.ngm),
  .pr-2:not(.ngm) {
    padding-right: $m-xs !important;
  }
  .pr-3:not(.ngm),
  .pr-4:not(.ngm),
  .pr-5:not(.ngm) {
    padding-right: $m-sm !important;
  }
  .px-1:not(.ngm),
  .px-2:not(.ngm) {
    padding-left: $m-xs !important;
    padding-right: $m-xs !important;
  }
  .px-3:not(.ngm),
  .px-4:not(.ngm),
  .px-5:not(.ngm) {
    padding-left: $m-sm !important;
    padding-right: $m-sm !important;
  }
  .py-1:not(.ngm),
  .py-2:not(.ngm) {
    padding-top: $m-xs !important;
    padding-bottom: $m-xs !important;
  }
  .py-3:not(.ngm),
  .py-4:not(.ngm),
  .py-5:not(.ngm) {
    padding-top: $m-sm !important;
    padding-bottom: $m-sm !important;
  }
  .p-1:not(.ngm),
  .p-2:not(.ngm) {
    padding-top: $m-xs !important;
    padding-bottom: $m-xs !important;
    padding-left: $m-xs !important;
    padding-right: $m-xs !important;
  }
  .p-3:not(.ngm),
  .p-4:not(.ngm),
  .p-5:not(.ngm) {
    padding-top: $m-sm !important;
    padding-bottom: $m-sm !important;
    padding-left: $m-sm !important;
    padding-right: $m-sm !important;
  }

  // A volte su iphone sopratutto nei dialog una parte di contenuto viene nascosto probabilmente dalla barra del browser
  .pb-mobile:not(.ngm) {
    padding-bottom: 32px !important;
  }
}

// Salesforce Chat styling
.embeddedServiceHelpButton .helpButton .uiButton {
  position: fixed;
  left: auto;
  bottom: 0;
  right: 12px;
  margin: 0;
  min-width: 12em;
  max-width: 14em;
  height: 46px;
  width: 192px;
  max-height: 100%;
  border-radius: 8px 8px 0 0;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
  pointer-events: all;
  overflow: hidden;
  background: #00a78c;
  font-size: 16px;
  font-family: "Arial", sans-serif;
}
div#CookiebotWidget:not(.CookiebotWidget-open) {
	position: relative;
    width: 48px;
    margin-top: -48px;
    left: 0;
    top: 0;
    display: block;
    height: 48px;
}
button.uiButton.helpButtonEnabled {
    transform: scale(.7);
    transform-origin: bottom right;
}
.embeddedServiceHelpButton .helpButton .uiButton:focus,
.embeddedServiceHelpButton .helpButton .uiButton:hover {
  color: rgb(255, 255, 255);
  outline: none;
  background: #006454;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
}
.embeddedServiceHelpButton .helpButton .uiButton .helpButtonLabel .message {
  padding: 0 8px;
  color: rgb(255, 255, 255);
  background-color: transparent;
  border: none;
}

.red-error{
  color: red !important
}

@media only screen and (max-width: 830px) { 
  .embeddedServiceHelpButton .uiButton {
    min-width:auto !important;
    margin:auto;
    width:auto !important;
  }
  .embeddedServiceHelpButton .embeddedServiceIcon {
    text-align: center;
    margin: auto !important;
    min-width:auto;
    display: inline-block !important;
  }
  .embeddedServiceHelpButton .helpButtonLabel {
    display:none !important;
  }
 
  .embeddedServiceHelpButton .embeddedServiceIcon::before {
    font-size: 2rem !important;
  }
  
  .embeddedServiceHelpButton .helpButton .uiButton {
    border-radius:10px !important;
    padding: 0 10px;
  }

  .dockableContainer .minimizeButton,
  .dockableContainer .closeButton {
    padding: 0;
  }

}