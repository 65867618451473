.sgb-input {
  border: 1px solid #cbcbcb;
  font-size: 14px;
  min-height: 44px;
  &.shape-rectangle {
    border-radius: 10px;
  }
  &::placeholder {
    font-size: 14px;
    opacity: 0.7;
  }
  &:focus {
    border-color: #cbcbcb;
    box-shadow: none;
  }
}
.color-status-danger {
  color: #d10b0bba !important;
  border-color: #d10b0bba !important;
}

.sgb-label {
  font-size: 0.9rem !important;
  padding-bottom: 5px !important;
  font-weight: 600 !important;
}

$primary_color: #0066b6;

tui-input-file {
  .wrapper:hover {
    background: transparent !important;
  }
}

tui-input-time tui-value-decoration {
  margin: -1.45rem 0 !important;
}

// .form-check {
//   padding-left: 0;
//   margin-top: 0.5rem;

//   [type="checkbox"],
//   [type="radio"] {
//     position: absolute;
//     left: -9999px;

//     + label {
//       position: relative;
//       padding-left: 36px;
//       cursor: pointer;
//       display: inline-block;
//       height: 32px;
//       line-height: 32px;
//       font-size: 1rem;
//       user-select: none;
//     }

//     //focus
//     &:focus + label {
//       @extend :focus;
//     }

//     &:focus.focus--mouse + label {
//       @extend .focus--mouse;
//     }
//   }

//   [type="checkbox"] {
//     + label::after,
//     + label::before {
//       content: "";
//       left: 0;
//       position: absolute;
//       transition: 0.2s ease-out;
//       z-index: 1;
//       border-style: solid;
//       border-width: 2px;
//     }

//     + label::before {
//       top: 0;
//       width: 17px;
//       height: 17px;
//       border: 1px solid #e6e9f2;
//       border-radius: 1px;
//       margin: 2px 5px;
//       transform: rotateZ(37deg);
//       transform-origin: 100% 100%;
//     }

//     + label::after {
//       border-radius: 4px;
//       height: 20px;
//       width: 20px;
//       margin: 6px 5px;
//       top: 0;
//     }

//     &:checked + label::before {
//       top: 6px;
//       left: 1px;
//       width: 8px;
//       height: 13px;
//       border-style: solid;
//       border-width: 2px;
//       border-color: transparent #fff #fff transparent;
//       transform: rotate(40deg);
//       backface-visibility: hidden;
//       transform-origin: 100% 100%;
//       opacity: 0.8;
//     }

//     &:checked + label::after {
//       border-color: $primary_color;
//       background-color: $primary_color;
//       z-index: 0;
//     }

//     &:not(:checked) + label::after {
//       background-color: transparent;
//       border-color: #5c6f82;
//       z-index: 0;
//     }

//     &:not(:checked) + label::before {
//       width: 0;
//       height: 0;
//       border-color: transparent;
//       left: 6px;
//       top: 10px;
//     }

//     &:indeterminate + label::after {
//       background-color: transparent;
//       border-color: #5c6f82;
//       z-index: 0;
//     }

//     &:indeterminate + label::before {
//       top: 13px;
//       left: 3px;
//       width: 14px;
//       border: 1px solid #5c6f82;
//       margin: 2px 5px;
//       transform: none;
//       transform-origin: inherit;
//     }

//     &:disabled + label {
//       cursor: not-allowed;
//     }

//     &:disabled:not(:checked) + label::after {
//       border-color: #e6e9f2;
//       background-color: #fff;
//     }

//     &:disabled:checked + label::after {
//       background-color: #e6e9f2;
//       border-color: #e6e9f2;
//     }
//   }

//   [type="radio"] {
//     + label {
//       transition: 0.2s ease-out;

//       &::after,
//       &::before {
//         content: "";
//         position: absolute;
//         left: 0;
//         top: 0;
//         margin: 5px;
//         width: 22px;
//         height: 22px;
//         z-index: 0;
//         border-radius: 50%;
//         border-style: solid;
//         border-width: 2px;
//         transition: 0.2s ease-out;
//       }
//     }

//     &:not(:checked) + label {
//       &::after,
//       &::before {
//         border-color: #5c6f82;
//       }

//       &::after {
//         z-index: -1;
//         transform: scale(0);
//       }
//     }

//     &:checked + label {
//       &::after {
//         border-color: $primary_color;
//         background-color: $primary_color;
//         z-index: 0;
//         transform: scale(0.64);
//       }

//       &::before {
//         border-color: $primary_color;
//       }
//     }

//     &:disabled {
//       & + label {
//         cursor: not-allowed;
//       }

//       &:not(:checked) + label {
//         &::after,
//         &::before {
//           border-color: #e6e9f2;
//         }
//       }

//       &:checked + label {
//         &::after {
//           border-color: #e6e9f2;
//           background-color: #e6e9f2;
//         }

//         &::before {
//           border-color: #e6e9f2;
//         }
//       }
//     }
//   }

//   // gruppi
//   &.form-check-group {
//     padding: 0 0 8;
//     margin-bottom: 16px;
//     box-shadow: inset 0 -1px 0 0 rgba(1, 1, 1, 0.1);

//     [type="checkbox"] + label,
//     [type="radio"] + label {
//       position: static;
//       padding-left: 8px;
//       padding-right: 52px;

//       &::after,
//       &::before {
//         right: 15px;
//         left: auto;
//       }
//     }

//     [type="checkbox"]:checked + label::before {
//       right: 26px;
//     }

//     [type="radio"]:checked + label::before {
//       right: 15px;
//     }

//     .form-text {
//       opacity: 0.6;
//       margin: 0;
//       padding-left: 8px;
//       padding-right: 52px;
//     }
//   }
// }

// .custom-input.input-full-width {
//   width: 100%;
// }

// .custom-input.shape-rectangle {
//   border-radius: 0.25rem;
// }

// .custom-input.color-status-danger {
//   background-color: #f7f9fc;
//   border-color: var(--danger);
//   color: #222b45;
// }

// .color-status-danger {
//   color: var(--danger);
//   border-color: var(--danger) !important;
// }

// .color-status-warning {
//   color: var(--warning);
//   border-color: var(--warning) !important;
// }

// .color-status-success {
//   color: var(--success);
//   border-color: var(--success) !important;
// }

// .color-status-info {
//   color: var(--info);
//   border-color: var(--info) !important;
// }

// .custom-input.nb-transition {
//   transition-duration: 0.15s;
//   transition-property: border, background-color, color, box-shadow;
//   transition-timing-function: ease-in;
// }

// .custom-input {
//   box-shadow: none !important;
//   border: 1px solid #c1bfbf !important;
//   color: ($active_input_color) !important;

//   padding: 0.584rem !important;
//   font-size: $font-xxs;
//   line-height: 1.5rem;

//   &:not(.input-full-width) {
//     max-width: 30rem;
//   }

//   &::placeholder {
//     font-size: 0.9375rem;
//     font-weight: 400;

//     line-height: 1.25rem;
//   }
// }

// input[type='search']::-webkit-search-cancel-button {
//   /* Remove default */
//   -webkit-appearance: none;

//   height: 10px;
//   width: 10px;
//   background-image: url('/assets/images/x.png');
//   background-repeat: round;
// }

// .form-check {
//   padding-left: 0;
//   margin-top: 0.5rem;

//   [type='checkbox'],
//   [type='radio'] {
//     position: absolute;
//     left: -9999px;

//     + label {
//       position: relative;
//       padding-left: 36px;
//       cursor: pointer;
//       display: inline-block;
//       height: 32px;
//       line-height: 32px;
//       font-size: 1rem;
//       user-select: none;
//     }

//     //focus
//     &:focus + label {
//       @extend :focus;
//     }

//     &:focus.focus--mouse + label {
//       @extend .focus--mouse;
//     }
//   }

//   [type='checkbox'] {
//     + label::after,
//     + label::before {
//       content: '';
//       left: 0;
//       position: absolute;
//       transition: 0.2s ease-out;
//       z-index: 1;
//       border-style: solid;
//       border-width: 2px;
//     }

//     + label::before {
//       top: 0;
//       width: 17px;
//       height: 17px;
//       border: 1px solid #e6e9f2;
//       border-radius: 1px;
//       margin: 2px 5px;
//       transform: rotateZ(37deg);
//       transform-origin: 100% 100%;
//     }

//     + label::after {
//       border-radius: 4px;
//       height: 20px;
//       width: 20px;
//       margin: 6px 5px;
//       top: 0;
//     }

//     &:checked + label::before {
//       top: 6px;
//       left: 1px;
//       width: 8px;
//       height: 13px;
//       border-style: solid;
//       border-width: 2px;
//       border-color: transparent #fff #fff transparent;
//       transform: rotate(40deg);
//       backface-visibility: hidden;
//       transform-origin: 100% 100%;
//       opacity: 0.8;
//     }

//     &:checked + label::after {
//       border-color: $primary_color;
//       background-color: $primary_color;
//       z-index: 0;
//     }

//     &:not(:checked) + label::after {
//       background-color: transparent;
//       border-color: #5c6f82;
//       z-index: 0;
//     }

//     &:not(:checked) + label::before {
//       width: 0;
//       height: 0;
//       border-color: transparent;
//       left: 6px;
//       top: 10px;
//     }

//     &:indeterminate + label::after {
//       background-color: transparent;
//       border-color: #5c6f82;
//       z-index: 0;
//     }

//     &:indeterminate + label::before {
//       top: 13px;
//       left: 3px;
//       width: 14px;
//       border: 1px solid #5c6f82;
//       margin: 2px 5px;
//       transform: none;
//       transform-origin: inherit;
//     }

//     &:disabled + label {
//       cursor: not-allowed;
//     }

//     &:disabled:not(:checked) + label::after {
//       border-color: #e6e9f2;
//       background-color: #fff;
//     }

//     &:disabled:checked + label::after {
//       background-color: #e6e9f2;
//       border-color: #e6e9f2;
//     }
//   }

//   [type='radio'] {
//     + label {
//       transition: 0.2s ease-out;

//       &::after,
//       &::before {
//         content: '';
//         position: absolute;
//         left: 0;
//         top: 0;
//         margin: 5px;
//         width: 22px;
//         height: 22px;
//         z-index: 0;
//         border-radius: 50%;
//         border-style: solid;
//         border-width: 2px;
//         transition: 0.2s ease-out;
//       }
//     }

//     &:not(:checked) + label {
//       &::after,
//       &::before {
//         border-color: #5c6f82;
//       }

//       &::after {
//         z-index: -1;
//         transform: scale(0);
//       }
//     }

//     &:checked + label {
//       &::after {
//         border-color: $primary_color;
//         background-color: $primary_color;
//         z-index: 0;
//         transform: scale(0.64);
//       }

//       &::before {
//         border-color: $primary_color;
//       }
//     }

//     &:disabled {
//       & + label {
//         cursor: not-allowed;
//       }

//       &:not(:checked) + label {
//         &::after,
//         &::before {
//           border-color: #e6e9f2;
//         }
//       }

//       &:checked + label {
//         &::after {
//           border-color: #e6e9f2;
//           background-color: #e6e9f2;
//         }

//         &::before {
//           border-color: #e6e9f2;
//         }
//       }
//     }
//   }

//   // gruppi
//   &.form-check-group {
//     padding: 0 0 8;
//     margin-bottom: 16px;
//     box-shadow: inset 0 -1px 0 0 rgba(1, 1, 1, 0.1);

//     [type='checkbox'] + label,
//     [type='radio'] + label {
//       position: static;
//       padding-left: 8px;
//       padding-right: 52px;

//       &::after,
//       &::before {
//         right: 15px;
//         left: auto;
//       }
//     }

//     [type='checkbox']:checked + label::before {
//       right: 26px;
//     }

//     [type='radio']:checked + label::before {
//       right: 15px;
//     }

//     .form-text {
//       opacity: 0.6;
//       margin: 0;
//       padding-left: 8px;
//       padding-right: 52px;
//     }
//   }
// }

// .custom-label {
//   font-size: 0.9rem !important;
//   padding: 0 !important;
//   color: $label_color;
//   font-weight: bold !important;
// }

// app-form-input[ng-reflect-name='operatore'] {
//   /* border: 1px solid var(---008cdc-subheader); */
//   background: #f7f7f7 0% 0% no-repeat padding-box;
//   border: 1px solid #008cdc;
//   border-radius: 6px;
//   opacity: 1;
//   display: block;
//   margin-top: 1rem;
//   & .form-group {
//     margin: 0;
//     padding: 1rem;
//     & label {
//       line-height: calc(1.5rem - 1px);
//     }
//     & input {
//       border: none !important;
//       background-color: transparent;
//       padding: 0 !important;
//       margin: 0 !important;
//       line-height: 1rem !important;
//       height: auto;
//     }
//   }
// }

// .home-form-inputs app-form-input,
// .home-form-inputs app-form-input {
//   & .form-group {
//     display: flex;
//     margin-bottom: 1rem;
//     margin-top: 1rem;
//     & label.custom-label {
//       color: white;
//       margin-right: 1rem;
//     }
//     & app-autocomplete-input {
//       width: 100%;
//     }
//   }
// }

// .custom-form-input app-form-input .form-group {
//   margin-bottom: 1rem;
// }

// app-form-input .custom-input.file-input.input-full-width.form-group {
//   padding: 0 0 0 0.5rem !important;
//   border-radius: 0.25rem;
// }

/*
tui-filter {
  tui-checkbox-block{
    &._active{

    }
  }
}
*/
