$green: #00a78c;
$green-dark: #006b6c;
$green-light: #d5ebeb;
$blue: #1690d9;
$yellow: #fdbb17;
$black: #191d19;
$white: #fff;
$white-green: #f2f7f7;
$red: #f94c43;
$gray: #f8f9fa;

$primary-100: #004242;
$primary-80: #006b6c;
$primary-60: #01a48d;
$primary-40: #21bf9f;

$neutral-100: #000000;
$neutral-60: #f5f5f5;
$neutral-20: #b3b3b3;
$neutral-10: #f3f3f3;
$neutral-0: #ffffff;

$primary: $primary-60;
$secondary: $primary-80;
$body-color: $black;
$primary-color: $primary-80;

$Anton: "Anton", sans-serif;
$Roboto: "Roboto", sans-serif;

$font-xxs: 0.9rem;
$font-xs: 1rem;
$font-sm: 1.2rem;
$font-md: 1.4rem;
$font-lg: 1.8rem;
$font-xl: 2rem;
$font-xxl: 2.8rem;
$font-xxxl: 3.4rem;

$style-radius: $font-lg;

$m-xs: 4px;
$m-sm: 8px;
$m-md: 12px;
$m-lg: 16px;
$m-xl: 20px;
$m-xxl: 24px;

:root {
  --Anton: "Anton", sans-serif;
  --Roboto: "Roboto", sans-serif;
  --primary-100: #004242;
  --primary-80: #006b6c;
  --primary-60: #01a48d;
  --primary-40: #21bf9f;
  --neutral-100: #000000;
  --neutral-60: #f5f5f5;
  --neutral-20: #b3b3b3;
  --neutral-10: #f3f3f3;
  --neutral-0: #ffffff;
  --tui-heading-font: "Anton", sans-serif !important; // TBD - change font
  --tui-text-font: "Roboto", sans-serif !important; // TBD - change font
  --tui-primary: $primary-60 !important; // primary buttons, background
  --tui-primary-text: $primary-80 !important;
  --tui-primary-hover: $primary-80 !important; // primary buttons hover
  --tui-primary-active: $primary-80 !important; // primary buttons hover
  --tui-link: $primary-60 !important;
  --tui-secondary: $black !important;
  --tui-secondary-hover: $primary-60 !important;
  --tui-link-hover: $primary-60 !important;
  --tui-input-grey: $white-green !important;
  --tui-top-title-bg: $white-green !important;
  --tui-text-01: $primary-color !important;
}

//KEYFRAMES
@mixin animate($animation, $duration, $delay) {
  animation-name: $animation;
  animation-duration: $duration;
  animation-delay: $delay;
}
@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

@mixin twist-animation($duration, $delay) {
  transform: rotate3d(0, 50, 0, 0deg);
  @include keyframes(twist-animation) {
    0% {
      transform: rotate3d(0, 50, 0, 0deg);
    }
    25% {
      transform: rotate3d(0, 50, 0, 45deg);
    }
    50% {
      transform: rotate3d(0, 50, 0, 90deg);
    }
    75% {
      transform: rotate3d(0, 50, 0, 135deg);
    }
    100% {
      transform: rotate3d(0, 50, 0, 180deg);
    }
  }
  @include animate(twist-animation, $duration, $delay);
}
@mixin twist-reverse-animation($duration, $delay) {
  transform: rotate3d(0, 50, 0, 180deg);
  @include keyframes(twist-reverse-animation) {
    0% {
      transform: rotate3d(0, 50, 0, 180deg);
    }
    25% {
      transform: rotate3d(0, 50, 0, 135deg);
    }
    50% {
      transform: rotate3d(0, 50, 0, 90deg);
    }
    75% {
      transform: rotate3d(0, 50, 0, 45deg);
    }
    100% {
      transform: rotate3d(0, 50, 0, 0deg);
    }
  }
  @include animate(twist-reverse-animation, $duration, $delay);
}

// SCROLLBAR
$scrollbar-thumb-width: 35px;
$scrollbar-thumb-height: 60px;
$scrollbar-thumb-color: $primary-60;
$scrollbar-thumb-color-hover: $primary-80;
$scrollbar-thumb-shadow-size: 4px;
$scrollbar-width: 4px;
$scrollbar-border: calc(
  calc(#{$scrollbar-thumb-width} - #{$scrollbar-width}) / 2
);
$scrollbar-bg-color: #d5ebeb;
$scrollbar-fill-color: $primary-80;
@mixin elevator-scrollbarV1() {
  &::-webkit-scrollbar {
    width: $scrollbar-thumb-width;
    background-color: transparent;
  }
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track-piece {
    border-radius: 5px;
    background-color: transparent;
    border-left: $scrollbar-border solid transparent;
    border-right: $scrollbar-border solid transparent;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-track-piece:start {
    background-color: $scrollbar-bg-color;
  }
  &::-webkit-scrollbar-track-piece:end {
    background-color: $scrollbar-fill-color;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-image: url("../../../assets/icons/elevator-thumb.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: initial;
    background-color: transparent;
    &:hover {
      background-image: url("../../../assets/icons/elevator-thumb-hover.svg");
    }
  }
}
@mixin elevator-scrollbarV2() {
  &::-webkit-scrollbar {
    width: $scrollbar-thumb-width;
    background-color: transparent;
  }
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track-piece {
    border-radius: 5px;
    background-color: transparent;
    border-left: $scrollbar-border solid transparent;
    border-right: $scrollbar-border solid transparent;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-track-piece:start {
    background-color: $scrollbar-bg-color;
  }
  &::-webkit-scrollbar-track-piece:end {
    background-color: $scrollbar-fill-color;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-image: url("../../../assets/icons/elevator-vertical-line.svg");
    background-repeat: repeat-y;
    background-position: center;
    background-size: initial;
    border-top: 10px solid $scrollbar-thumb-color;
    border-bottom: 10px solid $scrollbar-thumb-color;
    min-height: $scrollbar-thumb-height;
    background-color: $scrollbar-thumb-color;
    box-shadow: $scrollbar-thumb-shadow-size $scrollbar-thumb-shadow-size 0px
      0px $scrollbar-thumb-color-hover;
    &:hover {
      background-image: url("../../../assets/icons/elevator-vertical-line.svg");
      background-color: $scrollbar-thumb-color-hover;
      border-color: $scrollbar-thumb-color-hover;
      box-shadow: $scrollbar-thumb-shadow-size $scrollbar-thumb-shadow-size 0px
        0px $black;
    }
  }
}

@mixin fancy-scrollbar() {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: rgba($color: $green-light, $alpha: 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba($color: $green, $alpha: 0.2);
  }
}
@mixin dropdown-scrollbar($border-color: $white) {
  &::-webkit-scrollbar {
    width: 15px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: rgba($color: $green, $alpha: 0.1);
    border-right: 4px solid $border-color;
    background-clip: border-box;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba($color: $green, $alpha: 0.2);
    border-right: 4px solid $border-color;
    background-clip: border-box;
  }
}
@mixin dialog-scrollbar($border-color: transparent) {
  &::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: $border-color;
    border-top: 20px solid $border-color;
    border-bottom: 20px solid $border-color;
    background-clip: padding-box;
    border-right: 4px solid $border-color;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba($color: $green, $alpha: 0.2);
    border-top: 20px solid $border-color;
    border-bottom: 20px solid $border-color;
    background-clip: padding-box;
    border-right: 4px solid $border-color;
  }
}
@mixin main-scrollbar() {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: rgba($color: $green-dark, $alpha: 0.3);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba($color: $green-dark, $alpha: 0.5);
  }
}
