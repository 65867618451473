@import "src/app/theme/styles/variables";

.adsp-table {
  tbody tr td {
    font-size: $font-xs;
  }
  thead {
    th {
      vertical-align: middle !important;
      background-color: #f7f5f5;
      font-size: $font-xxs;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      table-layout: fixed;
      white-space: nowrap;
    }
  }
  .collapse.show.collapse-show-border {
    border-bottom: 1px solid #d6dce3;
  }
  .hiddenRow {
    padding: 0 !important;
  }
  .hide-bottom-border {
    border-bottom: hidden !important;
  }
  td[aria-expanded="true"] .icon,
  tr[aria-expanded="true"] .icon.expand {
    transform: scaleY(-1);
  }
  .sort {
    &:not(.asc):not(.desc):hover::after {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-bottom: 5px solid rgba(0, 0, 0, 0.4);
      border-top: 5px solid transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      margin-bottom: 3px;
    }
    &.asc,
    &.desc {
      font-weight: bold;
      &::after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-bottom: 5px solid black;
        border-top: 5px solid transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        margin-bottom: 3px;
      }
    }
    &.desc::after {
      -webkit-transform: rotate(-180deg);
      transform: rotate(-180deg);
      margin-bottom: -3px;
    }
  }
}

table {
  color: $green-dark !important;
  .black-color {
    color: black !important;
  }
  .white-color {
    color: white !important;
  }
  thead {
    border-bottom: 3px solid $green-light;
  }
  tr {
    border-color: $green-light;
  }
}
app-table {
  display: block;
}
